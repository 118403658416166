 

import PropTypes from "prop-types";
import axios from 'axios'
import config from 'config'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import RequestsPage from './RequestsPage'
import { withTranslation } from "react-i18next";

const Investor = (props) => {

    const type = 'investor'


    const navigate = useNavigate()

    const { addToast } = useToasts();

    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = () => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem('token');
        axios.post(`${config.apiUrl}/page/request/${type}/index`)
            .then(res => {
                if (res.data.msg === "Success") {
                    console.log(res)
                    setData(res.data.data)
                }

            }).catch(err => {
                console.log(err)
                if (err.response?.status === 401) {
                    navigate('/login')
                    // localStorage.removeItem('token')
                }
            })
    }

    const handleChangeStatus = (cell) => {
        axios.post(`${config.apiUrl}/page/request/${type}/update`, {
            id: cell.cell.row.values.id,
            status: cell.cell.row.values.status === 1 ? 0 : 1
        })
            .then(res => {
                if (res.data.msg === "Success") {
                    addToast(props.t('saved_success'), { appearance: 'success' });
                    // setData(res.data.data)
                    fetchData()
                }
                else {
                    addToast(props.t('saved_error'), { appearance: 'error' });
                }

            }).catch(err => {
                console.log(err)
                if (err.response?.status === 401) {
                    navigate('/login')
                    // localStorage.removeItem('token')
                }
            })
    }

    return (
        <div>

            <RequestsPage
                type={type}
                data={data}
                fetchData={fetchData}
                handleChangeStatus={handleChangeStatus}
            />

        </div>
    )
}
 
Investor.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(Investor);