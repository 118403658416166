
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    Container,
    TabContent,
    TabPane,
} from "reactstrap";
//import action 

//i18n
import { withTranslation } from "react-i18next";
import TableContainer from "components/Common/TableContainer";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import config from "config";
import { useNavigate, useParams } from "react-router-dom";
import { isIterableArray } from "helpers/utils";
import { t } from "i18next";


const RequestsMain = (props) => {

    const { handleDownloadFile, handleChangeStatus, data } = props

    document.title = "Request Page";

    const StatusCell = cellProps => {
        return (
            <div className="d-flex flex-column">
                <Badge
                    className={"mb-2 font-size-11 badge-soft-" +
                        (cellProps.value === 1 ? "success" : "danger")}
                >
                    {cellProps.value === 1 ? "answered" : "no-answered"}
                </Badge>
                <Button
                    size="sm"
                    onClick={() => { handleChangeStatus(cellProps) }}
                >
                    {t('Change')}
                </Button>
            </div>
        )
    }

    const columns = useMemo(
        () => (
            {
                contact: [
                    {
                        Header: "ID",
                        accessor: "id",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';
                        },
                    },
                    {
                        Header: "Name",
                        accessor: "name",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';
                        },
                    },
                    {
                        Header: "Date",
                        accessor: "date",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';
                        },
                    },
                    {
                        Header: "E-mail",
                        accessor: "email",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';
                        },
                    },
                    {
                        Header: "Question",
                        accessor: "question",
                        disableFilters: true,
                        filterable: false,
                        // Cell: cellProps => {
                        //     return cellProps.cell.value ? cellProps.cell.value : '';;
                        // },
                    },
                    {
                        Header: "Status",
                        accessor: "status",
                        disableFilters: true,
                        filterable: false,
                        Cell: StatusCell,
                    },
                ],
                career: [
                    {
                        Header: "ID",
                        accessor: "id",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Name",
                        accessor: "name",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Second name",
                        accessor: "second_name",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Date",
                        accessor: "date",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "About",
                        accessor: "about",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "E-mail",
                        accessor: "email",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Telegram",
                        accessor: "telegram",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Recomendation",
                        accessor: "recommendation",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "PDF",
                        accessor: "pdf_name",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return (
                                <>
                                    {
                                        cellProps.value ?
                                            <>
                                                <Button size='sm' className="w-100" color="success" onClick={() => handleDownloadFile(cellProps.value)}> 
                                                        <i className="bx bx-download"></i> 
                                                </Button>
                                            </>
                                            :
                                            <div className="d-flex justify-content-center">
                                            <Badge className={"font-size-11 badge-soft-danger"} >
                                                <i className="bx bx-x"></i>
                                            </Badge>
                                            </div>
                                    }
                                </>
                            )
                        },
                    },
                    {
                        Header: "Status",
                        accessor: "status",
                        disableFilters: true,
                        filterable: false,
                        Cell: StatusCell,
                    },
                ],
                investor: [
                    {
                        Header: "ID",
                        accessor: "id",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Name",
                        accessor: "name",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Second name",
                        accessor: "second_name",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Date",
                        accessor: "date",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Portfolio",
                        accessor: "portfolio",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            // console.log(cellProps)
                            const arr = cellProps.cell.value
                            return isIterableArray(cellProps.cell.value) ?
                                arr.map((item, key) => (
                                    <Badge key={key} className={"d-inline-block mb-1 me-2 font-size-11 badge-soft-light"}>
                                        {item}
                                    </Badge>
                                ))
                                :
                                cellProps.cell.value ? cellProps.cell.value : '';
                        },
                    },
                    {
                        Header: "Summ",
                        accessor: "sum",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "E-mail",
                        accessor: "email",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Telegram",
                        accessor: "telegram",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Recomendation",
                        accessor: "recommendation",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Is USA",
                        accessor: "is_usa",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return (
                                <Badge
                                    className={"font-size-11 badge-soft-" +
                                        (cellProps.value ? "success" : "danger")}
                                >
                                    {
                                        cellProps.value ?
                                            <i className="bx bx-check"></i>
                                            :
                                            <i className="bx bx-x"></i>
                                    }
                                </Badge>
                            )
                        },
                    },
                    {
                        Header: "Status",
                        accessor: "status",
                        disableFilters: true,
                        filterable: false,
                        Cell: StatusCell,
                    },
                ],
            }
        ),
        []
    );

    return (

        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-4">
                                {props.t('Requests')} {' - '} {props.t(`${props.type}`)}
                            </h4>
                            {
                                !!data &&
                                <TableContainer
                                    columns={columns[props.type]}
                                    data={data}
                                    isGlobalFilter={true}
                                    isAddOptions={false}
                                    // customPageSize={false}
                                    customPageSizeOptions={false}
                                    customPageSize={10}
                                />
                            }
                        </CardBody>
                    </Card>
                </Container>
            </div>

        </React.Fragment>
    )
}


RequestsMain.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(RequestsMain);
