// import { TextField } from 'common/fields'
import React from 'react'
import { Button, Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap'
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Controller } from "react-hook-form";
import { DefaultEditor, EditorProvider, Editor, Toolbar, BtnBold, BtnUndo, BtnRedo, Separator } from 'react-simple-wysiwyg';
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { useFieldArray } from 'react-hook-form'
import { isIterableArray } from 'helpers/utils';
import { TextEditor, TextField } from 'common/fields';

const FormMain = props => {

    const { lang, register, errors, control, onReady } = props

    const partners_items = useFieldArray({ control, name: `${lang}.blocks.partners.items`, });
    const history_items = useFieldArray({ control, name: `${lang}.blocks.history.items`, });
    const profit_years = useFieldArray({ control, name: `${lang}.blocks.profit.years`, });


    return (
        <div>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">{props.t('Meta')}</h4>
                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.title}
                        name={`${lang}.meta.title`}
                        label={props.t('meta_title')}
                    />
                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.description}
                        name={`${lang}.meta.description`}
                        label={props.t('meta_description')}
                    />
                </CardBody>
            </Card>

            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('block_video')}
                    </h4>
                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.video_banner.title`}
                        onReady={onReady}
                    />


                    <TextEditor
                        control={control}
                        error={errors?.[lang]?.blocks?.video_banner?.subtitle}
                        name={`${lang}.blocks.video_banner.subtitle`}
                        label={props.t('subtitle')}
                        onReady={onReady}
                    />
                </CardBody>
            </Card>

            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('block_partners')}
                    </h4>

                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.partners.title`}
                        onReady={onReady}
                    />

                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.partners.description`}
                        label={props.t('description')}
                        onReady={onReady}
                    />

                    <h4 className="card-title my-4">
                        {props.t('Items')}
                    </h4>

                    {
                        isIterableArray(partners_items.fields) && partners_items.fields.map((item, index) => (
                            <Row key={index} >
                                <Col lg={4}>
                                    <TextEditor
                                        control={control}
                                        error={errors?.[lang]?.blocks?.partners?.items?.[index]?.title}
                                        name={`${lang}.blocks.partners.items.${index}.title`}
                                        label={props.t('title')}
                                        onReady={onReady}
                                    />
                                </Col>
                                <Col lg={8}>
                                    <TextEditor
                                        control={control}
                                        name={`${lang}.blocks.partners.items.${index}.subtitle`}
                                        label={props.t('description')}
                                        onReady={onReady}
                                    />
                                </Col>
                            </Row>
                        ))
                    }

                </CardBody>
            </Card>


            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('block_history')}
                    </h4>
                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.history.title`}
                        onReady={onReady}
                    />


                    <h4 className="card-title mt-4">
                        {props.t('items')}
                    </h4>

                    {
                        isIterableArray(history_items.fields) && history_items.fields.map((item, index) => (
                            <Row key={index} className='border-bottom mt-2'>
                                <Col lg={4}>
                                    <TextEditor
                                        control={control}
                                        name={`${lang}.blocks.history.items.${index}.title`}
                                        label={props.t('title')}
                                        onReady={onReady}
                                    />
                                    <TextEditor
                                        control={control}
                                        name={`${lang}.blocks.history.items.${index}.year`}
                                        label={props.t('year')}
                                        onReady={onReady}
                                    />
                                </Col>
                                <Col lg={8}>
                                    <TextEditor
                                        control={control}
                                        name={`${lang}.blocks.history.items.${index}.description`}
                                        label={props.t('description')}
                                        onReady={onReady}
                                    />
                                </Col>
                            </Row>
                        ))
                    }

                </CardBody>
            </Card>
            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('block_profit')}
                    </h4>

                    <Row>
                        <Col lg={7}>
                            <TextEditor
                                control={control}
                                name={`${lang}.blocks.profit.title`}
                                label={props.t('title')}
                                onReady={onReady}
                            />
                            <TextEditor
                                control={control}
                                name={`${lang}.blocks.profit.subtitle`}
                                label={props.t('subtitle')}
                                onReady={onReady}
                            />
                            <Row>
                                <Col lg={6}>
                                    <TextField
                                        register={register}
                                        error={errors?.[lang]?.blocks?.profit?.total_title}
                                        name={`${lang}.blocks.profit.total_title`}
                                        label={props.t('total_title')}
                                    />
                                </Col>
                                <Col lg={6}>
                                    <TextField
                                        register={register}
                                        error={errors?.[lang]?.blocks?.profit?.total_short_title}
                                        name={`${lang}.blocks.profit.total_short_title`}
                                        label={props.t('total_short_title')}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <TextField
                                        register={register}
                                        error={errors?.[lang]?.blocks?.profit?.total}
                                        name={`${lang}.blocks.profit.total`}
                                        label={props.t('total')}
                                    />
                                </Col>
                                <Col lg={6}>
                                    <TextField
                                        type={'number'}
                                        register={register}
                                        error={errors?.[lang]?.blocks?.profit?.speed_range}
                                        name={`${lang}.blocks.profit.speed_range`}
                                        label={props.t('speed_range')}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={5}>

                            {
                                isIterableArray(profit_years.fields) && profit_years.fields.map((item, index) => (
                                    <Row key={index} className={`${profit_years.fields.length - 1 > index ? 'border-bottom' : ''} g-3 mt-1`}>
                                        <Col lg={5}>
                                            <TextField
                                                register={register}
                                                error={errors?.[lang]?.blocks?.profit?.years?.[index]?.year}
                                                name={`${lang}.blocks.profit.years.${index}.year`}
                                                label={props.t('year')}
                                            />
                                        </Col>
                                        <Col lg={7}>
                                            <div className='d-flex align-items-end'>

                                                <TextField
                                                    register={register}
                                                    error={errors?.[lang]?.blocks?.profit?.years?.[index]?.total}
                                                    name={`${lang}.blocks.profit.years.${index}.total`}
                                                    label={props.t('total')}
                                                    className='flex-grow-1'
                                                />

                                                <Button
                                                    className='btn-danger ms-2 mb-3'
                                                    onClick={() => { profit_years.remove(index) }}>
                                                    <i className="bx bx-trash font-size-16 align-middle"></i>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                ))
                            }
                            <Button
                                color='primary'
                                onClick={() => {
                                    profit_years.append()
                                }}
                            >
                                <i className="fas fa-plus font-size-16 align-middle me-2"></i>{" "}
                                {props.t('Add')}
                            </Button>
                        </Col>
                    </Row>



                </CardBody>
            </Card>
        </div>
    )
}


FormMain.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(FormMain);
