// import { TextField } from 'common/fields'
import React from 'react'
import { Button, Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap'
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Controller } from "react-hook-form";
import { DefaultEditor, EditorProvider, Editor, Toolbar, BtnBold, BtnUndo, BtnRedo, Separator } from 'react-simple-wysiwyg';
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { useFieldArray } from 'react-hook-form'
import { isIterableArray } from 'helpers/utils';
import { TextareaField, TextEditor, TextField } from 'common/fields';


const FormInvest = props => {

    const { lang, register, errors, control, onReady } = props

    const portfolios = useFieldArray({ control, name: `${lang}.blocks.portfolios`, });

    return (
        <div>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">{props.t('Meta')}</h4>

                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.title}
                        name={`${lang}.meta.title`}
                        label={props.t('meta_title')}
                    />
                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.description}
                        name={`${lang}.meta.description`}
                        label={props.t('meta_description')}
                    />
                </CardBody>
            </Card>

            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('block_banner')}
                    </h4>

                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.banner.title`}
                        label={props.t('title')}
                        onReady={onReady}
                    />
                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.banner.subtitle`}
                        label={props.t('subtitle')}
                        onReady={onReady}
                    />
                </CardBody>

            </Card>
            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('block_portfolios')}
                    </h4>
                    {
                        isIterableArray(portfolios.fields) && portfolios.fields.map((item, index) => (
                            <div key={index} >
                                <div className='d-flex justify-content-end mt-3' style={{ marginBottom: '-30px' }}>
                                    <Button
                                        className='btn-danger'
                                        onClick={() => { portfolios.remove(index) }}>
                                        <i className="bx bx-trash font-size-16 align-middle"></i>
                                    </Button>
                                </div>
                                <Row className={`${portfolios.fields.length - 1 > index ? 'border-bottom' : ''} g-3 mt-1`}>
                                    <Col lg={5}>
                                        <TextEditor
                                            className='w-100'
                                            control={control}
                                            name={`${lang}.blocks.portfolios.${index}.title`}
                                            label={props.t('title')}
                                            onReady={onReady}
                                        />
                                    </Col>
                                    <Col lg={7}>
                                        <TextEditor
                                            control={control}
                                            name={`${lang}.blocks.portfolios.${index}.paragraph`}
                                            label={props.t('description')}
                                            onReady={onReady}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ))
                    }
                    <Button
                        color='primary'
                        onClick={() => {
                            portfolios.append()
                        }}
                    >
                        <i className="fas fa-plus font-size-16 align-middle me-2"></i>{" "}
                        {props.t('Add')}
                    </Button>

                </CardBody>
            </Card>



        </div >
    )
}


FormInvest.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(FormInvest);
