
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Container,
    TabContent,
    TabPane,
} from "reactstrap";
//import action 

//i18n
import { withTranslation } from "react-i18next";

import { useForm } from 'react-hook-form'
import { languages } from "config";
import FormPolicy from "./FormPolicy";
import LanguagesTabs from "common/LanguagesTabs"; 
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import config from "config";

const PolicyPage = (props) => {

    const navigate = useNavigate()

    const { register, handleSubmit, control, reset, formState: { errors } } = useForm();

    const { addToast } = useToasts();

    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setactiveTab] = useState("1");

    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = () => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem('token');
        axios.post(`${config.apiUrl}/page/admin-page-index`, {
            page: 'policy'
        }).then(res => {
            if (res.data.msg === "Success") {
                const resdata = {}
                res.data.data.forEach((item) => {
                    resdata[item.lang] = item.value
                });
                reset(resdata)
            }

        }).catch(err => {
            console.log(err)
            if (err.response?.status === 401) {
                navigate('/login')
                // localStorage.removeItem('token')
            }
        })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleSave = values => {
        setIsLoading(true)

        axios.post(`${config.apiUrl}/page/admin-page-edit`, {
            page: 'policy',
            data: JSON.stringify(values)
        })
            .then(res => {
                addToast(props.t('saved_success'), { appearance: 'success' });
            })
            .catch(err => {
                addToast(props.t('saved_error'), { appearance: 'error' });
                console.log(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const onEditorReady = () => {
        if (isLoading) setIsLoading(false)
    }


    document.title = "Policy Page";

    const toggle = tab => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };

    return (

        <React.Fragment>
            <div className="page-content pt-header">
                <Container fluid>
                    <form onSubmit={handleSubmit(handleSave)}  >

                        <LanguagesTabs
                            activeTab={activeTab}
                            toggle={toggle}
                            title={props.t('Policy page')}
                            isLoading={isLoading}
                        />

                        <TabContent activeTab={activeTab} className="">
                            <TabPane tabId="1">
                                <FormPolicy
                                    lang={languages[0]}
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    onReady={onEditorReady}
                                />
                            </TabPane>
                            <TabPane tabId="2">
                                <FormPolicy
                                    lang={languages[1]}
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    onReady={onEditorReady}
                                />
                            </TabPane>
                            <TabPane tabId="3">
                                <FormPolicy
                                    lang={languages[2]}
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    onReady={onEditorReady}
                                />
                            </TabPane>
                        </TabContent>

                    </form>
                </Container>
            </div>

        </React.Fragment>
    )
}


PolicyPage.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(PolicyPage);
