// import { TextField } from 'common/fields'
import React from 'react'
import { Button, Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap'
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { useFieldArray } from 'react-hook-form'
import { isIterableArray } from 'helpers/utils';
import { TextareaField, TextEditor, TextField } from 'common/fields';


const FormCareer = props => {

    const { lang, register, errors, control, onReady } = props

    const rec_list = useFieldArray({ control, name: `${lang}.blocks.form_block.form.recomendation`, });

    return (
        <div>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">{props.t('Meta')}</h4>

                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.title}
                        name={`${lang}.meta.title`}
                        label={props.t('meta_title')}
                    />
                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.description}
                        name={`${lang}.meta.description`}
                        label={props.t('meta_description')}
                    />
                </CardBody>
            </Card>

            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('block_banner')}
                    </h4>

                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.banner.title`}
                        label={props.t('title')}
                        onReady={onReady}
                    />

                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.banner.paragraph`}
                        label={props.t('description')}
                        onReady={onReady}
                    />
                </CardBody>

            </Card>

            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('form_block')}
                    </h4>

                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.form_block.title`}
                        label={props.t('title')}
                        onReady={onReady}
                    />

                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.form_block.subtitle`}
                        onReady={onReady}
                    />
                    <Row>
                        <Col lg={6}>
                            <Row>
                                <Col lg={12}>
                                    <TextField
                                        register={register}
                                        name={`${lang}.blocks.form_block.form.first_name`}
                                        label={props.t('first_name')}
                                    />
                                </Col>
                                <Col lg={12}>
                                    <TextField
                                        register={register}
                                        name={`${lang}.blocks.form_block.form.second_name`}
                                        label={props.t('second_name')}
                                    />
                                </Col>
                                <Col lg={12}>
                                    <TextField
                                        register={register}
                                        name={`${lang}.blocks.form_block.form.about_name`}
                                        label={props.t('about_name')}
                                    />
                                </Col>
                                <Col lg={12}>
                                    <TextField
                                        register={register}
                                        error={errors?.[lang]?.blocks?.form_block?.form?.about_placeholder}
                                        name={`${lang}.blocks.form_block.form.about_placeholder`}
                                        label={props.t('about_placeholder')}
                                    />
                                </Col>
                                <Col lg={12}>
                                    <TextField
                                        register={register}
                                        name={`${lang}.blocks.form_block.form.email_name`}
                                        label={props.t('email_name')}
                                    />
                                </Col>
                                <Col lg={12}>
                                    <TextField
                                        register={register}
                                        name={`${lang}.blocks.form_block.form.tg_name`}
                                        label={props.t('tg_name')}
                                    />
                                </Col>
                                <Col lg={12}>
                                    <TextField
                                        register={register}
                                        name={`${lang}.blocks.form_block.form.pdf_file_name`}
                                        label={props.t('pdf_file_name')}
                                    />
                                </Col>
                                <Col lg={12}>
                                    <TextField
                                        register={register}
                                        error={errors?.[lang]?.blocks?.form_block?.form?.submit_button}
                                        name={`${lang}.blocks.form_block.form.submit_button`}
                                        label={props.t('submit_button')}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} className='border-left'>

                            <div className={`d-flex justify-content-end`} style={{ marginBottom: -20 }}>
                                <Button
                                    color='primary'
                                    onClick={() => {
                                        rec_list.append()
                                    }}
                                >
                                    <i className="fas text-white fa-plus font-size-16 align-middle me-2"></i>{" "}
                                    {props.t('Add')}
                                </Button>
                            </div>
                            <TextField
                                register={register}
                                name={`${lang}.blocks.form_block.form.recomendation_name`}
                                label={props.t('recomendation_name')}
                            />
                            {
                                isIterableArray(rec_list.fields) && rec_list.fields.map((item, index) => (
                                    <Row key={index} className={`g-2`}>
                                        <Col>
                                            <TextField
                                                register={register}
                                                error={errors?.[lang]?.blocks?.form_block?.form?.recomendation?.[index]?.term}
                                                name={`${lang}.blocks.form_block.form.recomendation.${index}.term`}
                                            />
                                        </Col>
                                        <Col className='col-auto'>
                                            <Button
                                                className='btn-danger'
                                                onClick={() => { rec_list.remove(index) }}>
                                                <i className="bx bx-trash font-size-16 align-middle"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                ))
                            }

                            <h4 className="card-title mb-4 mt-3">
                                {props.t('Tooltip texts')}
                            </h4>
                            <TextField
                                label='Success'
                                className='flex-grow-1'
                                register={register}
                                name={`${lang}.blocks.form_block.form.tooltip_success`}
                            />
                            <TextField
                                label='Error'
                                className='flex-grow-1'
                                register={register}
                                name={`${lang}.blocks.form_block.form.tooltip_error`}
                            />

                        </Col>
                    </Row>
                </CardBody>

            </Card>
        </div >
    )
}


FormCareer.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(FormCareer);
