// import { TextField } from 'common/fields'
import React from 'react'
import { Button, Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap'
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { useFieldArray } from 'react-hook-form'
import { isIterableArray } from 'helpers/utils';
import { TextareaField, TextEditor, TextField } from 'common/fields';


const FormUser = props => {

    const { lang, register, errors, control, onReady } = props


    return (
        <div>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">{props.t('Meta')}</h4>

                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.title}
                        name={`${lang}.meta.title`}
                        label={props.t('meta_title')}
                    />
                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.description}
                        name={`${lang}.meta.description`}
                        label={props.t('meta_description')}
                    />
                </CardBody>
            </Card>

            <Card >
                <CardBody>
                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.banner.title`}
                        label={props.t('title')}
                        onReady={onReady}
                    />
                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.banner.subtitle`}
                        label={props.t('subtitle')}
                        onReady={onReady}
                    />
                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.banner.link_text`}
                        label={props.t('link_text')}
                        onReady={onReady}
                    />
                </CardBody>

            </Card>

        </div >
    )
}


FormUser.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(FormUser);
