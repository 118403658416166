import React, { useState, useEffect, useMemo } from 'react'
import { Card, CardBody, Container, Badge, Button, Input, FormGroup, Label } from 'reactstrap'

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import axios from 'axios';
import config from 'config';
import { useNavigate } from 'react-router-dom';
import TableContainer from 'components/Common/TableContainer';
import { isIterableArray } from 'helpers/utils';
import { useToasts } from 'react-toast-notifications';
import fileDownload from 'js-file-download';


const CommonRequests = (props) => {

    const navigate = useNavigate()

    const { addToast } = useToasts();

    const [dataCareer, setDataCareer] = useState(null);
    const [dataInvest, setDataInvest] = useState(null);
    const [dataContact, setDataContact] = useState(null);

    const [searchValue, setSearchValue] = useState('');


    const fetchDataCareer = () => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem('token');
        axios.post(`${config.apiUrl}/page/request/career/index`)
            .then(res => {
                if (res.data.msg === "Success") {
                    setDataCareer(res.data.data)
                }

            }).catch(err => {
                console.log(err)
                if (err.response?.status === 401) {
                    navigate('/login')
                    // localStorage.removeItem('token')
                }
            })
    }

    const fetchDataContact = () => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem('token');
        axios.post(`${config.apiUrl}/page/request/contact/index`)
            .then(res => {
                if (res.data.msg === "Success") {
                    setDataContact(res.data.data)
                }

            }).catch(err => {
                console.log(err)
                if (err.response?.status === 401) {
                    navigate('/login')
                    // localStorage.removeItem('token')
                }
            })
    }
    const fetchDataInvest = () => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem('token');
        axios.post(`${config.apiUrl}/page/request/investor/index`)
            .then(res => {
                if (res.data.msg === "Success") {
                    setDataInvest(res.data.data)
                }

            }).catch(err => {
                console.log(err)
                if (err.response?.status === 401) {
                    navigate('/login')
                    // localStorage.removeItem('token')
                }
            })
    }


    const handleChangeStatus = (cell, type) => {
        axios.post(`${config.apiUrl}/page/request/${type}/update`, {
            id: cell.cell.row.values.id,
            status: cell.cell.row.values.status === 1 ? 0 : 1
        })
            .then(res => {
                console.log(res)
                if (res.data.msg === "Success") {
                    addToast(props.t('saved_success'), { appearance: 'success' });
                    fetchPageData()
                }
                else {
                    addToast(props.t('saved_error'), { appearance: 'error' });
                }

            }).catch(err => {
                console.log(err)
                if (err.response?.status === 401) {
                    navigate('/login')
                    // localStorage.removeItem('token')
                }
            })
    }

    const handleDownloadFile = (filename) => {
        axios.post(`${config.apiUrl}/page/request/contact/downloadFile`, {
            name: `${filename}.pdf`
        }, { responseType: 'blob' })
            .then(response => {
                console.log(response)
                fileDownload(response.data, `${filename}.pdf`)
                addToast(props.t('filesave_success'), { appearance: 'success' });
            }).catch(err => {

                addToast(props.t('filesave_error'), { appearance: 'error' });


                console.log(err)
                if (err.response?.status === 401) {
                    navigate('/login')
                }
            })
    }


    const fetchPageData = () => {
        fetchDataCareer()
        fetchDataContact()
        fetchDataInvest()
    }
    useEffect(() => {
        fetchPageData()
    }, []);



    const StatusCell = ({ cellProps, type }) => {
        return (
            <div className="d-flex flex-column">
                <Badge
                    className={"mb-2 font-size-11 badge-soft-" +
                        (cellProps.value === 1 ? "success" : "danger")}
                >
                    {cellProps.value === 1 ? "answered" : "no-answered"}
                </Badge>
                <Button
                    size="sm"
                    onClick={() => { handleChangeStatus(cellProps, type) }}
                >
                    {props.t('Change')}
                </Button>
            </div>
        )
    }

    const columns = useMemo(
        () => (
            {
                contact: [
                    {
                        Header: "ID",
                        accessor: "id",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';
                        },
                    },
                    {
                        Header: "Name",
                        accessor: "name",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';
                        },
                    },
                    {
                        Header: "Date",
                        accessor: "date",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';
                        },
                    },
                    {
                        Header: "E-mail",
                        accessor: "email",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';
                        },
                    },
                    {
                        Header: "Question",
                        accessor: "question",
                        disableFilters: true,
                        filterable: false,
                        // Cell: cellProps => {
                        //     return cellProps.cell.value ? cellProps.cell.value : '';;
                        // },
                    },
                    {
                        Header: "Status",
                        accessor: "status",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return <StatusCell cellProps={cellProps} type={'contact'} />
                        },
                    },
                ],
                career: [
                    {
                        Header: "ID",
                        accessor: "id",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Name",
                        accessor: "name",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Second name",
                        accessor: "second_name",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Date",
                        accessor: "date",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "About",
                        accessor: "about",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "E-mail",
                        accessor: "email",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Telegram",
                        accessor: "telegram",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Recomendation",
                        accessor: "recommendation",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "PDF",
                        accessor: "pdf_name",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return (
                                <>
                                    {
                                        cellProps.value ?
                                            <>
                                                <Button size='sm' className="w-100" color="success" onClick={() => handleDownloadFile(cellProps.value)}>
                                                    <i className="bx bx-download"></i>
                                                </Button>
                                            </>
                                            :
                                            <div className="d-flex justify-content-center">
                                                <Badge className={"font-size-11 badge-soft-danger"} >
                                                    <i className="bx bx-x"></i>
                                                </Badge>
                                            </div>
                                    }
                                </>
                            )
                        },
                    },
                    {
                        Header: "Status",
                        accessor: "status",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return <StatusCell cellProps={cellProps} type={'career'} />
                        },
                    },
                ],
                investor: [
                    {
                        Header: "ID",
                        accessor: "id",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Name",
                        accessor: "name",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Second name",
                        accessor: "second_name",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Date",
                        accessor: "date",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Portfolio",
                        accessor: "portfolio",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            // console.log(cellProps)
                            const arr = cellProps.cell.value
                            return isIterableArray(cellProps.cell.value) ?
                                arr.map((item, key) => (
                                    <Badge key={key} className={"d-inline-block mb-1 me-2 font-size-11 badge-soft-light"}>
                                        {item}
                                    </Badge>
                                ))
                                :
                                cellProps.cell.value ? cellProps.cell.value : '';
                        },
                    },
                    {
                        Header: "Summ",
                        accessor: "sum",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "E-mail",
                        accessor: "email",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Telegram",
                        accessor: "telegram",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Recomendation",
                        accessor: "recommendation",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return cellProps.cell.value ? cellProps.cell.value : '';;
                        },
                    },
                    {
                        Header: "Is USA",
                        accessor: "is_usa",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return (
                                <Badge
                                    className={"font-size-11 badge-soft-" +
                                        (cellProps.value ? "success" : "danger")}
                                >
                                    {
                                        cellProps.value ?
                                            <i className="bx bx-check"></i>
                                            :
                                            <i className="bx bx-x"></i>
                                    }
                                </Badge>
                            )
                        },
                    },
                    {
                        Header: "Status",
                        accessor: "status",
                        disableFilters: true,
                        filterable: false,
                        Cell: cellProps => {
                            return <StatusCell cellProps={cellProps} type={'investor'} />
                        },
                    },
                ],
            }
        ),
        []
    );

    return (
        <div className="page-content">
            <Container fluid>
                <Card>
                    <CardBody>
                        <div className='d-flex align-items-center justify-content-between flex-column flex-md-row'>
                            <h4 className="card-title mb-2">
                                {props.t('Requests')}
                            </h4>
                            <div>
                                <Label>{props.t('Search')}</Label>
                                <Input
                                    placeholder={props.t('Search')}
                                    value={searchValue}
                                    onChange={e => {
                                        console.log(e.target.value)
                                        setSearchValue(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">
                            {props.t('From Career')}
                        </h4>
                        {
                            !!dataCareer &&
                            <TableContainer
                                columns={columns['career']}
                                data={dataCareer}
                                isGlobalFilter={true}
                                // customPageSize={false}
                                isAddOptions={false}
                                customPageSizeOptions={false}
                                customPageSize={5}

                                searchValue={searchValue}
                            />
                        }
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">
                            {props.t('From Become investor')}
                        </h4>
                        {
                            !!dataInvest &&
                            <TableContainer
                                columns={columns['investor']}
                                data={dataInvest}
                                isGlobalFilter={true}
                                // customPageSize={false}
                                isAddOptions={false}
                                customPageSizeOptions={false}
                                customPageSize={5}
                                searchValue={searchValue}
                            />
                        }
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">
                            {props.t('From Footer contact')}
                        </h4>
                        {
                            !!dataContact &&
                            <TableContainer
                                columns={columns['contact']}
                                data={dataContact}
                                isGlobalFilter={true}
                                // customPageSize={false}
                                isAddOptions={false}
                                customPageSizeOptions={false}
                                customPageSize={5}
                                searchValue={searchValue}
                            />
                        }
                    </CardBody>
                </Card>
            </Container>
        </div >
    )
}
CommonRequests.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(CommonRequests);

