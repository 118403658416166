
// import { TextField } from 'common/fields'
import React from 'react'
import { Button, Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap'
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { useFieldArray } from 'react-hook-form'
import { isIterableArray } from 'helpers/utils';
import { TextEditor, TextField } from 'common/fields';


const FormPolicy = props => {

    const { lang, register, errors, control, onReady } = props

    const rules_list = useFieldArray({ control, name: `${lang}.blocks.text_block`, });

    return (
        <div>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">{props.t('Meta')}</h4>

                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.title}
                        name={`${lang}.meta.title`}
                        label={props.t('meta_title')}
                    />
                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.description}
                        name={`${lang}.meta.description`}
                        label={props.t('meta_description')}
                    />
                </CardBody>
            </Card>

            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('block_banner')}

                    </h4>

                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.banner.title`}
                        label={props.t('title')}
                        onReady={onReady}
                    />
                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.banner.paragraph`}
                        label={props.t('paragraph')}
                        isExtend={true}
                        onReady={onReady}
                    />
                </CardBody>

            </Card>
            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('Rules list')}
                    </h4>
                    {
                        isIterableArray(rules_list.fields) && rules_list.fields.map((item, index) => (

                            <Row key={index} className={`${rules_list.fields.length - 1 > index ? 'border-bottom' : ''} g-3 mt-1`}>
                                <Col lg={5}>
                                    <TextField
                                        register={register}
                                        error={errors?.[lang]?.blocks?.text_block?.[index]?.title}
                                        name={`${lang}.blocks.text_block.${index}.title`}
                                    />
                                </Col>
                                <Col lg={7}>
                                    <div className='d-flex align-items-start'>
                                        <TextEditor
                                            className='flex-grow-1'
                                            control={control}
                                            name={`${lang}.blocks.text_block.${index}.text`}
                                            onReady={onReady}
                                        />
                                        <Button
                                            className='btn-danger ms-2'
                                            onClick={() => { rules_list.remove(index) }}>
                                            <i className="bx bx-trash font-size-16 align-middle"></i>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        ))
                    }

                    <Button
                        color='primary'
                        onClick={() => {
                            rules_list.append()
                        }}
                    >
                        <i className="fas fa-plus font-size-16 align-middle me-2"></i>{" "}
                        {props.t('Add')}
                    </Button>
                </CardBody>

            </Card>
        </div >
    )
}


FormPolicy.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(FormPolicy);
