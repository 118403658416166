// import { TextField } from 'common/fields'
import React from 'react'
import { Button, Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap'
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Controller } from "react-hook-form";
import { DefaultEditor, EditorProvider, Editor, Toolbar, BtnBold, BtnUndo, BtnRedo, Separator } from 'react-simple-wysiwyg';
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { useFieldArray } from 'react-hook-form'
import { isIterableArray } from 'helpers/utils';
import { TextareaField, TextEditor, TextField } from 'common/fields';

const FormInvest = props => {

    const { lang, register, errors, control, onReady } = props

    const direction_points = useFieldArray({ control, name: `${lang}.blocks.direction.points`, });
    const evolution_points = useFieldArray({ control, name: `${lang}.blocks.evolution.items`, });


    return (
        <div>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">{props.t('Meta')}</h4>

                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.title}
                        name={`${lang}.meta.title`}
                        label={props.t('meta_title')}
                    />
                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.description}
                        name={`${lang}.meta.description`}
                        label={props.t('meta_description')}
                    />
                </CardBody>
            </Card>

            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('block_direction')}
                    </h4>

                    <Row>
                        <Col lg={8}>
                            <TextEditor
                                control={control}
                                name={`${lang}.blocks.direction.title`}
                                label={props.t('title')}
                                onReady={onReady}
                            />
                        </Col>
                        <Col lg={4}>
                            <TextField
                                type={'number'}
                                register={register}
                                error={errors?.[lang]?.blocks?.direction?.speed_range}
                                name={`${lang}.blocks.direction.speed_range`}
                                label={props.t('speed_range')}
                            />
                        </Col>
                    </Row>


                    {
                        isIterableArray(direction_points.fields) && direction_points.fields.map((item, index) => (
                            <Row key={index} className={`${direction_points.fields.length - 1 > index ? 'border-bottom' : ''} g-3 mt-1`}>
                                <Col lg={5}>

                                    <TextEditor
                                        control={control}
                                        name={`${lang}.blocks.direction.points.${index}.title`}
                                        label={props.t('title')}
                                        onReady={onReady}
                                    />
                                </Col>
                                <Col lg={7}>
                                    <TextEditor
                                        control={control}
                                        name={`${lang}.blocks.direction.points.${index}.paragraph`}
                                        label={props.t('description')}
                                        onReady={onReady}
                                    />
                                </Col>
                            </Row>
                        ))
                    }

                </CardBody>
            </Card>


            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('block_evolution')}
                    </h4>

                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.evolution.title`}
                        label={props.t('title')}
                        onReady={onReady}
                    />


                    {
                        isIterableArray(evolution_points.fields) && evolution_points.fields.map((item, index) => (
                            <Row key={index} className={`${evolution_points.fields.length - 1 > index ? 'border-bottom' : ''} g-3 mt-1`}>
                                <Col lg={5}>

                                    <TextEditor
                                        control={control}
                                        name={`${lang}.blocks.evolution.items.${index}.title`}
                                        label={props.t('title')}
                                        onReady={onReady}
                                    />
                                </Col>
                                <Col lg={7}>
                                    <TextEditor
                                        control={control}
                                        name={`${lang}.blocks.evolution.items.${index}.paragraph`}
                                        label={props.t('description')}
                                        onReady={onReady}
                                    />
                                </Col>
                            </Row>
                        ))
                    }

                </CardBody>
            </Card>

        </div >
    )
}


FormInvest.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(FormInvest);
