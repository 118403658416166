import PropTypes from 'prop-types';
import React, { useContext, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import axios from 'axios';
import AppContext from 'context/AppContext';
import { ToastProvider } from 'react-toast-notifications';


const App = () => {

  const navigate = useNavigate()

  const { token, setToken } = useContext(AppContext)


  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
    if (localStorage.getItem('token')) {
      setToken(localStorage.getItem('token'))
      axios.defaults.headers.common["Authorization"] = localStorage.getItem('token');
    } else {
      console.log('remove')
      localStorage.removeItem('token')
      navigate('/login')
    }
  }, [token]);

  return (
    <ToastProvider placement='bottom-right' transitionDuration={500} autoDismiss={true}>
      <React.Fragment>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <NonAuthLayout>
                  {route.component}
                </NonAuthLayout>
              }
              key={idx}
              exact={true}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  <VerticalLayout>{route.component}</VerticalLayout>
                </Authmiddleware>}
              key={idx}
              exact={true}
            />
          ))}
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </React.Fragment>
    </ToastProvider>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;