
import AppContext from "./AppContext";
import { useState } from "react";

const AppProvider = (props) => {

  const [token, setToken] = useState(null);
  return (
    <AppContext.Provider value={{ token, setToken }}  >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
