// import { TextField } from 'common/fields'
import React, { useState, useEffect } from 'react'
import { Button, Card, CardBody, Col, Form, FormGroup, Row, Tooltip } from 'reactstrap'
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { useFieldArray } from 'react-hook-form'
import { isIterableArray } from 'helpers/utils';
import { TextEditor, TextField } from 'common/fields';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { t } from 'i18next';


const FormFaq = props => {

    const { lang, register, errors, control, onReady } = props

    const faq_list = useFieldArray({ control, name: `${lang}.blocks.faq.items`, });

    const [isValidLength, setIsValidLength] = useState(false);

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    useEffect(() => {
        if (faq_list?.fields.length) {
            if (faq_list.fields.length % 3 === 0) {
                setIsValidLength(true)
            }
            else setIsValidLength(false)
        } else setIsValidLength(false)
    }, [faq_list.fields]);

    return (
        <div>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">{props.t('Meta')}</h4>

                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.title}
                        name={`${lang}.meta.title`}
                        label={props.t('meta_title')}
                    />
                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.description}
                        name={`${lang}.meta.description`}
                        label={props.t('meta_description')}
                    />
                </CardBody>
            </Card>

            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('block_banner')}
                    </h4>

                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.faq.title`}
                        label={props.t('title')}
                        onReady={onReady}
                    />
                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.faq.footer_title`}
                        label={props.t('footer_title')}
                        onReady={onReady}
                    />
                </CardBody>

            </Card>

            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('items')}
                        <p className='d-inline-block p-3' id='Tooltip-faq'>  {
                                isValidLength ?
                                    <i className={`text-success bx bx-check`}></i>
                                    :
                                    <i className={`text-danger bx bx-question-mark`}></i>
                            }
                        </p>
                    </h4>
                    <Tooltip
                        placement={'top'}
                        isOpen={tooltipOpen}
                        target={'Tooltip-faq'}
                        toggle={toggle}
                    >
                        {t('faq_length_validation')}
                    </Tooltip>

                    <Row className={`g-3 mt-1 mb-3`}>
                        {
                            isIterableArray(faq_list.fields) && faq_list.fields.map((item, index) => (
                                <Col lg={4} key={index} className={`border-bottom`}>

                                    <div className={`d-flex justify-content-end`} style={{ marginBottom: -20 }}>
                                        {/* {
                                            (index + 1) % 3 === 0 &&
                                           
                                        } */}
                                        <Button
                                            size='sm'
                                            className='btn-danger'
                                            onClick={() => { faq_list.remove(index) }}>
                                            <i className="bx bx-trash font-size-16 align-middle"></i>
                                        </Button>
                                    </div>
                                    <TextEditor
                                        control={control}
                                        // className='flex-grow-1'
                                        name={`${lang}.blocks.faq.items.${index}.title`}
                                        label={props.t('title')}
                                        onReady={onReady}
                                    />
                                    <TextEditor
                                        control={control}
                                        name={`${lang}.blocks.faq.items.${index}.paragraph`}
                                        onReady={onReady}
                                    />
                                </Col>
                            ))
                        }
                    </Row>
                    <Button
                        color='primary'
                        onClick={() => {
                            faq_list.append()
                        }}
                    >
                        <i className="fas fa-plus font-size-16 align-middle me-2"></i>{" "}
                        {props.t('add')}
                    </Button>

                </CardBody>
            </Card>
        </div >
    )
}


FormFaq.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(FormFaq);
