import PropTypes from 'prop-types';
import React, { useState } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// Import menuDropdown 
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logo from "../../assets/images/logo.svg";
import logoLightSvg from "../../assets/images/logo-light.svg";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  changeLayoutMode
} from "../../store/actions";

import { layoutModeTypes } from 'constants/layout';
import { FormGroup, Input, Label } from 'reactstrap';
import ReactSwitch from 'react-switch';
import { defaultHomePage } from 'config';
import LogoIconSmall from 'assets/LogoIconSmall';

const Header = props => {

  const dispatch = useDispatch()

  const {
    layoutModeType,
  } = useSelector(state => ({
    layoutModeType: state.Layout.layoutModeType,
  }));

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }

  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to={defaultHomePage} className="logo logo-dark">
                {/* <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span> */}
                <LogoIconSmall width={'100%'} />
              </Link>

              <Link to="/" className="logo logo-light">
                <LogoIconSmall width={'100%'} />
                {/* <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span> */}
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">

            <div className="d-flex align-items-center mx-2">
              <ReactSwitch
                uncheckedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 15,
                      color: "#fff",
                    }}
                  >
                    <i className="bx bx-moon" />
                  </div>
                }
                checkedIcon={

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 15,
                      color: "#fff",
                    }}
                  >
                    <i className="bx bx-moon" />
                  </div>
                }
                className="me-1 mb-sm-8 mb-2"
                onColor="#3959F9"
                offColor={'#555b6d'}
                height={22}
                width={48}
                onChange={() => {
                  const mode = layoutModeType === layoutModeTypes.DARK ? layoutModeTypes.LIGHT : layoutModeTypes.DARK
                  dispatch(changeLayoutMode(mode))
                }}
                checked={layoutModeType === layoutModeTypes.DARK}
              />

            </div>
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <ProfileMenu />

            {/* <div className="radio-toolbar">
              <span className="mb-2 d-block">Layouts Mode</span>
              <input
                type="radio"
                id="radioLight"
                name="radioLight"
                value={layoutModeTypes.LIGHT}
                checked={layoutModeType === layoutModeTypes.LIGHT}
                onChange={e => {
                  changeLayoutMode('LIGHT')
                  console.log(e)
                  if (e.target.checked) {
                    console.log(e)
                    dispatch(changeLayoutMode(e.target.value))
                  }
                }}
              />
              <label className="me-1" htmlFor="radioLight">Light1</label>
              <input
                type="radio"
                id="radioDark"
                name="radioDark"
                value={layoutModeTypes.DARK}
                checked={layoutModeType === layoutModeTypes.DARK}
                onChange={e => {
                  console.log(e)
                  if (e.target.checked) {
                    console.log(e)
                    dispatch(changeLayoutMode(e.target.value));
                  }
                }}
              />
              <label htmlFor="radioDark">Dark</label>
            </div> */}

            {/* <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog" />
              </button>
            </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  return { ...state.Layout };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
