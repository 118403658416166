import React from "react";
import { Navigate } from "react-router-dom";

import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

//Contacts 
import Main from "pages/main/Main";
import About from "pages/about/About";
import Invest from "pages/investments/Invest";
import Become from "pages/become-investor/Become";
import Education from "pages/education/Education";
import Career from "pages/career/Career";
import Faq from "pages/faq/Faq";
import User from "pages/user/User";
import SettingsMain from "pages/settings/SettingsMain";
import PolicyPage from "pages/settings/policy/PolicyPage"; 
import InvestorReq from "pages/settings/requests/Investor";
import CareerReq from "pages/settings/requests/Career";
import ContactReq from "pages/settings/requests/Contact";

import { defaultHomePage } from "config";
import Login from "pages/Login";
import CommonRequests from "pages/settings/requests/CommonRequests";

const authProtectedRoutes = [

  { path: "/page/about-us", component: <Main /> },
  { path: "/page/what-do-we-do", component: <About /> },
  { path: "/page/investment-strategies", component: <Invest /> },
  { path: "/page/become-investor", component: <Become /> },
  { path: "/page/education", component: <Education /> },
  { path: "/page/career", component: <Career /> },
  { path: "/page/faq", component: <Faq /> },
  { path: "/page/investor-login", component: <User /> },
  { path: "/settings/policy", component: <PolicyPage /> },
  { path: "/settings/main", component: <SettingsMain /> },
  // { path: "/requests/:typeRequest", component: <RequestsPage /> },
  { path: "/requests/investor", component: <InvestorReq /> },
  { path: "/requests/career", component: <CareerReq /> },
  { path: "/requests/contact", component: <ContactReq /> },
  { path: "/requests", component: <CommonRequests /> },



  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: < Navigate to={defaultHomePage} />,
  },
];

const publicRoutes = [
  // { path: "/logout", component: <Logout /> },
  // { path: "/login", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },

  // { path: "/pages-maintenance", component: <PagesMaintenance /> },
  // { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
  // { path: "/crypto-ico-landing", component: <CryptoIcoLanding /> },

  // Authentication Inner
  { path: "/login", component: <Login /> },
  // { path: "/pages-login-2", component: <Login2 /> },
  // { path: "/pages-register", component: <Register1 /> },
  // { path: "/pages-register-2", component: <Register2 /> },
  // { path: "/page-recoverpw", component: <Recoverpw /> },
  // { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  // { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  // { path: "/auth-recoverpw-2", component: <ForgetPwd2 /> },
  // { path: "/auth-lock-screen", component: <LockScreen /> },
  // { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
  // { path: "/page-confirm-mail", component: <ConfirmMail /> },
  // { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  // { path: "/auth-email-verification", component: <EmailVerification /> },
  // { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
  // { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  // { path: "/auth-two-step-verification-2", component: <TwostepVerification2 /> },
];

export { authProtectedRoutes, publicRoutes };
