// import { TextField } from 'common/fields'
import React from 'react'
import { Button, Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap'
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { useFieldArray } from 'react-hook-form'
import { isIterableArray } from 'helpers/utils';
import { TextareaField, TextEditor, TextField } from 'common/fields';

const FormEducation = props => {

    const { lang, register, errors, control, onReady } = props

    const system_list = useFieldArray({ control, name: `${lang}.blocks.system.items`, });
    const evolution_points = useFieldArray({ control, name: `${lang}.blocks.evolution.items`, });


    return (
        <div>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">{props.t('Meta')}</h4>

                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.title}
                        name={`${lang}.meta.title`}
                        label={props.t('meta_title')}
                    />
                    <TextField
                        register={register}
                        error={errors?.[lang]?.meta?.description}
                        name={`${lang}.meta.description`}
                        label={props.t('meta_description')}
                    />
                </CardBody>
            </Card>

            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('block_banner')}
                    </h4>

                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.banner.title`}
                        label={props.t('title')}
                        onReady={onReady}
                    />
                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.banner.paragraph`}
                        label={props.t('paragraph')}
                        onReady={onReady}
                    />

                </CardBody>
            </Card>
            <Card >
                <CardBody>
                    <h4 className="card-title mb-4">
                        {props.t('block_system')}
                    </h4>

                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.system.title`}
                        label={props.t('title')}
                        onReady={onReady}
                    />
                    <TextEditor
                        control={control}
                        name={`${lang}.blocks.system.paragraph`}
                        label={props.t('paragraph')}
                        onReady={onReady}
                    />


                    {
                        isIterableArray(system_list.fields) && system_list.fields.map((item, index) => (
                            <Row key={index} className={`${system_list.fields.length - 1 > index ? 'border-bottom' : ''} g-3 mt-1`}>
                                <Col lg={5}>
                                    <TextEditor
                                        control={control}
                                        name={`${lang}.blocks.system.items.${index}.title`}
                                        label={props.t('title')}
                                        onReady={onReady}
                                    />
                                </Col>
                                <Col lg={7}>
                                    <TextEditor
                                        control={control}
                                        name={`${lang}.blocks.system.items.${index}.paragraph`}
                                        label={props.t('description')}
                                        onReady={onReady}
                                    />
                                </Col>
                            </Row>
                        ))
                    }

                </CardBody>
            </Card>


        </div >
    )
}


FormEducation.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(FormEducation);
