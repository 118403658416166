import React from 'react'
import { Col, Nav, NavItem, NavLink, Row, Button, Spinner } from 'reactstrap';
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import PropTypes from "prop-types";
import { languages } from "config";

const LanguagesTabs = (props) => {
    return (
        <div className='fixed-header'>
            <Row>
                <Col className="col-12">
                    <div className="d-sm-flex align-items-center justify-content-between pb-2 pt-3">
                        <h4 className="mb-sm-0 font-size-18">{props.title}</h4>
                        <div className='d-flex align-items-center '>
                            {
                                props.isLoading && <Spinner className='me-3' />
                            }
                            <Button
                                type="submit"
                                color='primary'
                                className="btn btn-primary px-4"
                                size="md"
                            >
                                {props.t('Save')}
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Nav tabs className="fixed-header_nav">
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            active: props.activeTab === "1",
                        })}
                        onClick={() => {
                            props.toggle("1");
                        }}
                    >
                        {props.t(languages[0])}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            active: props.activeTab === "2",
                        })}
                        onClick={() => {
                            props.toggle("2");
                        }}
                    >
                        {props.t(languages[1])}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            active: props.activeTab === "3",
                        })}
                        onClick={() => {
                            props.toggle("3");
                        }}
                    >
                        {props.t(languages[2])}
                    </NavLink>
                </NavItem>
            </Nav>
        </div>
    )
}

LanguagesTabs.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(LanguagesTabs); 