import React from 'react'

const LogoIconSmall = ({ width = "225", height = "34" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 80 34" fill="none" xmlns="http://www.w3.org/2000/svg"> 
            <path fillRule="evenodd" clipRule="evenodd" d="M42.8098 20.3958C42.1951 21.5525 41.4707 22.6542 40.6458 23.687C40.0443 24.4861 39.3619 25.2277 38.6081 25.901C37.9921 26.4396 37.4392 26.9781 36.9338 27.397L35.9702 27.9954C32.1318 30.2992 24.5813 30.3141 21.5801 26.7687C20.9865 25.9462 20.6824 24.9671 20.7113 23.9712C20.6636 22.9682 20.9154 21.9726 21.438 21.099C22.75 19.3731 24.5287 18.014 26.5874 17.1646L28.167 18.8101C27.3842 19.3834 26.7397 20.1083 26.2767 20.9365C25.8136 21.7647 25.5425 22.6769 25.4817 23.6122C25.4539 24.1976 25.5501 24.7823 25.7645 25.3321C25.9787 25.8819 26.3069 26.3856 26.7296 26.8136C29.0358 29.0575 33.6008 27.9355 35.8754 26.0656C36.7657 25.288 37.6093 24.4638 38.4028 23.5972C39.4913 22.2921 40.4836 20.9176 41.3724 19.4833L42.8098 20.3958Z" fill="#3959F9" />
            <path d="M38.0237 28.878H43.1889L39.4927 25.1979L38.1974 23.8515L31.1682 16.6559C32.195 16.032 33.1718 15.3369 34.0905 14.5765C34.967 13.9076 35.7244 13.1095 36.3335 12.2129C36.8709 11.385 37.1554 10.4322 37.1549 9.46032C37.1688 8.76722 37.0168 8.08014 36.7104 7.45057C36.4041 6.82101 35.9512 6.2653 35.3858 5.82511C33.8256 4.7734 31.9242 4.27556 30.0151 4.4189C28.034 4.32864 26.076 4.85395 24.4392 5.91487C23.7758 6.40129 23.2458 7.03173 22.8939 7.75297C22.5421 8.47421 22.3786 9.26503 22.4173 10.0587C22.4214 10.9023 22.604 11.7365 22.9544 12.5121C23.2979 13.2746 23.7325 13.9974 24.2496 14.6663C24.6888 15.2497 25.1691 15.8046 25.6871 16.3268L38.0237 28.878ZM29.0358 14.397C28.733 14.044 28.4684 13.6631 28.246 13.2601C27.979 12.7878 27.7773 12.2851 27.6458 11.7641C27.4759 11.1794 27.391 10.5754 27.393 9.96895C27.363 9.14057 27.4696 8.31283 27.7089 7.51556C27.8407 7.00802 28.1531 6.55896 28.5935 6.24399C29.0142 5.97947 29.5103 5.84369 30.0151 5.85503C30.4236 5.86399 30.8199 5.98909 31.1524 6.21407C31.5867 6.53408 31.9156 6.96485 32.1002 7.45572C32.3839 8.21362 32.5126 9.01582 32.4793 9.81935C32.4949 10.861 32.2343 11.8898 31.7211 12.8113C31.1932 13.6719 30.5331 14.4534 29.7624 15.13C29.5 14.9043 29.2571 14.6592 29.0358 14.397Z" fill="#f8f8fb" />
            <path d="M8.56425 29.4465C7.53532 29.449 6.50871 29.3537 5.49984 29.1622C4.5455 28.9845 3.61733 28.6982 2.73555 28.3095C1.97661 28.0031 1.29739 27.5437 0.745271 26.9632C0.266343 26.4636 0.00158743 25.8128 0.00286226 25.1381V20.9793H1.06119C1.21682 22.9614 2.08031 24.8344 3.50956 26.29C4.91607 27.3899 6.7123 27.9409 8.53266 27.8308C9.39281 27.8441 10.2486 27.7125 11.06 27.4419C11.7183 27.2094 12.2935 26.8047 12.7186 26.275C13.1248 25.7068 13.3295 25.031 13.303 24.3452C13.3191 23.8744 13.2351 23.4054 13.056 22.9659C12.8769 22.5264 12.6064 22.1256 12.2605 21.7871C11.4326 20.9874 10.498 20.2932 9.48041 19.7227L4.74164 17.0598C3.30873 16.3335 2.06698 15.3109 1.10858 14.0679C0.384362 13.0518 -0.000854713 11.8531 0.00286226 10.6272C-0.0263469 9.758 0.168369 8.89515 0.570181 8.11323C0.971993 7.3313 1.56881 6.65379 2.30907 6.13927C4.13853 4.96513 6.32673 4.39708 8.53266 4.52362C9.56164 4.52037 10.5883 4.61559 11.5971 4.80786C12.5565 4.98567 13.4898 5.27195 14.3771 5.66056C15.1318 5.9741 15.8096 6.43255 16.3675 7.00693C16.8447 7.50636 17.1043 8.15851 17.0941 8.83201V13.0058H16.0358C15.8604 11.0339 15.0121 9.16975 13.6189 7.69507C12.978 7.14854 12.2296 6.72714 11.4164 6.45502C10.6032 6.18292 9.74136 6.06544 8.88017 6.10935C8.06703 6.09539 7.25984 6.24319 6.51078 6.54318C5.87389 6.79027 5.32553 7.20573 4.93119 7.73995C4.57268 8.29795 4.39169 8.94201 4.40992 9.59495C4.40852 10.0542 4.51392 10.508 4.71869 10.9243C4.92343 11.3406 5.2225 11.709 5.59462 12.0035C6.47816 12.7387 7.43577 13.3901 8.45368 13.9482L13.1925 16.5662C14.5906 17.3326 15.8405 18.3191 16.8887 19.4833C17.7005 20.4334 18.1263 21.6275 18.0892 22.8492C18.1244 23.7901 17.9186 24.725 17.4896 25.5743C17.0604 26.4236 16.4207 27.1618 15.625 27.7261C13.5341 29.0168 11.0533 29.6212 8.56425 29.4465Z" fill="#f8f8fb" />
            <path d="M47.2011 28.8331V9.80439C47.2373 8.91487 47.0758 8.02808 46.7272 7.20141C46.4113 6.54318 45.6847 6.21407 44.5474 6.21407H44.2315V5.13697H54.4672C55.6519 5.13697 56.8998 5.13697 58.1951 5.33144C59.4455 5.45456 60.6728 5.73634 61.8439 6.16919C62.9196 6.55688 63.8679 7.20718 64.5924 8.05411C65.3449 9.02668 65.719 10.216 65.6507 11.42C65.598 12.5322 65.1339 13.5913 64.3397 14.412C63.2886 15.5024 61.9013 16.251 60.3749 16.5512C61.737 16.6066 63.0668 16.9599 64.2607 17.5834C65.1167 18.0575 65.7999 18.767 66.2194 19.6179C66.6009 20.4379 66.7947 21.325 66.788 22.2209C66.8459 23.4012 66.5086 24.5689 65.8245 25.5569C65.1581 26.4508 64.2457 27.1545 63.1866 27.5915C62.0387 28.0847 60.8196 28.4123 59.5693 28.5638C58.2456 28.7434 56.9101 28.8333 55.5729 28.8331H47.2011ZM52.1294 15.878H54.9727C55.9107 15.8957 56.8436 15.7434 57.7212 15.4292C58.5026 15.1371 59.1624 14.6119 59.6009 13.9333C60.0952 13.1105 60.3308 12.171 60.2801 11.2256C60.3433 10.228 60.0421 9.24022 59.4271 8.4281C58.8696 7.73285 58.1047 7.21179 57.2315 6.93213C56.3199 6.64404 55.3651 6.49754 54.404 6.4983H53.4721C53.0192 6.53323 52.5701 6.60327 52.1294 6.70774V15.878ZM54.9727 27.397C56.6608 27.4906 58.3322 27.0369 59.7115 26.1104C60.2531 25.6546 60.6771 25.0871 60.951 24.4517C61.2249 23.8164 61.3411 23.13 61.2911 22.4453C61.3486 21.7533 61.2359 21.0581 60.9619 20.4144C60.6878 19.7707 60.2597 19.1959 59.7115 18.7353C58.299 17.8071 56.5919 17.3686 54.8779 17.4937H52.1294V27.0379C52.4138 27.1127 52.7929 27.1875 53.2983 27.2773C53.8523 27.3578 54.4121 27.3977 54.9727 27.397Z" fill="#f8f8fb" />
            
        </svg>

    )
}

export default LogoIconSmall