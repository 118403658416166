// import { TextField } from 'common/fields'
import React from 'react'
import { Button, Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap'
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { useFieldArray } from 'react-hook-form'
import { isIterableArray } from 'helpers/utils';
import { TextareaField, TextEditor, TextField } from 'common/fields';


const FormSettings = props => {

    const { lang, register, errors, control, setValue } = props

    const menu_items = useFieldArray({ control, name: `${lang}.routes`, });
    const languages = useFieldArray({ control, name: `${lang}.languages`, });
    const social_media = useFieldArray({ control, name: `${lang}.footer.social_media`, });


    const handleDisableLanguage = item => {
        console.log(item)
        const newarr = languages.fields.map(field => {
            if (field.value === item.value) return { ...field, disabled: !field.disabled }

            return field
        })
        setValue(`${lang}.languages`, newarr)
    }

    return (
        <div>

            <Row>
                <Col lg={6}>
                    <Card >
                        <CardBody>
                            <h4 className="card-title mb-4">
                                {props.t('Languages')}
                            </h4>
                            {
                                isIterableArray(languages.fields) && languages.fields.map((item, index) => (
                                    <Row key={index} className={`g-2`}>
                                        <Col>
                                            <TextField
                                                disabled
                                                register={register}
                                                error={errors?.languages?.[index]?.term}
                                                name={`${lang}.languages.${index}.term`}
                                            />
                                        </Col>
                                        <Col className='col-auto'>
                                            <Button
                                                color={item.disabled ? 'success' : "warning"}
                                                className={`btn ${item.disabled ? 'btn-success' : "btn-warning"}`}
                                                style={{ minWidth: 120 }}
                                                onClick={() => handleDisableLanguage(item)}
                                            >
                                                <i className={`bx ${item.disabled ? 'bx-check' : 'bx-block'} font-size-16 align-middle me-2`}></i>
                                                {item.disabled ? 'Activate' : 'Disable'}
                                            </Button>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </CardBody>

                    </Card>
                    <Card >
                        <CardBody>
                            <h4 className="card-title mb-4">
                                {props.t('Menu items')}
                            </h4>
                            {
                                isIterableArray(menu_items.fields) && menu_items.fields.map((item, index) => (
                                    <Row key={index} className={`g-2`}>
                                        <Col>
                                            <TextField
                                                register={register}
                                                error={errors?.[lang]?.routes?.[index]?.term}
                                                name={`${lang}.routes.${index}.term`}
                                            />
                                        </Col>
                                    </Row>
                                ))
                            }
                        </CardBody>

                    </Card>
                    <Card >
                        <CardBody>
                            <h4 className="card-title mb-4">
                                {props.t('Footer')}
                            </h4>
                            <TextField
                                register={register}
                                name={`${lang}.footer.policy_text`}
                                error={errors?.footer?.social_media?.policy_text}
                                label={props.t('policy_text')}
                            />
                            <TextEditor
                                control={control}
                                name={`${lang}.footer.copy_text`}
                                label={props.t('copy_text')}
                            />
                            <TextField
                                register={register}
                                name={`${lang}.footer.contact_btn`}
                                error={errors?.footer?.social_media?.contact_btn}
                                label={props.t('contact_btn')}
                            />

                        </CardBody>

                    </Card>
                </Col>
                <Col lg={6}> 
                    <Card >
                        <CardBody>
                            <h4 className="card-title mb-4">
                                {props.t('Social media')}
                            </h4>
                            {
                                isIterableArray(social_media.fields) && social_media.fields.map((item, index) => (
                                    <div key={index} className='d-flex align-items-center'>
                                        <i className={`bx bxl-${item.icon} fs-2 align-middle me-3 mb-3`}></i>
                                        <TextField
                                            className='flex-grow-1'
                                            register={register}
                                            name={`${lang}.footer.social_media.${index}.href`}
                                            error={errors?.footer?.social_media?.[index]?.href}
                                        />
                                    </div>
                                ))
                            }
                        </CardBody>

                    </Card>
                    <Card>
                        <CardBody>

                            <h5 className="card-title mb-3">
                                {props.t('footer_contact_modal')}
                            </h5>

                            <TextEditor
                                control={control}
                                name={`${lang}.footer.contact_modal.title`}
                                error={errors?.footer?.contact_modal?.title}
                                label={props.t('title')}
                            />
                            <TextEditor
                                control={control}
                                name={`${lang}.footer.contact_modal.paragraph`}
                                error={errors?.footer?.contact_modal?.paragraph}
                                label={props.t('paragraph')}
                            />
                            <TextField
                                type='email'
                                register={register}
                                name={`${lang}.footer.contact_modal.contact_email`}
                                error={errors?.footer?.contact_modal?.contact_email}
                                label={props.t('contact_email')}
                            />
                            <TextField
                                register={register}
                                name={`${lang}.footer.contact_modal.form.first_name`}
                                error={errors?.footer?.contact_modal?.form?.first_name}
                                label={props.t('first_name')}
                            />
                            <TextField
                                register={register}
                                name={`${lang}.footer.contact_modal.form.email_name`}
                                error={errors?.footer?.contact_modal?.form?.email_name}
                                label={props.t('email_name')}
                            />
                            <TextField
                                register={register}
                                name={`${lang}.footer.contact_modal.form.about_name`}
                                error={errors?.footer?.contact_modal?.form?.about_name}
                                label={props.t('about_name')}
                            />
                            <TextField
                                register={register}
                                name={`${lang}.footer.contact_modal.form.about_placeholder`}
                                error={errors?.footer?.contact_modal?.form?.about_placeholder}
                                label={props.t('about_placeholder')}
                            />
                            <TextField
                                register={register}
                                name={`${lang}.footer.contact_modal.form.submit_button`}
                                error={errors?.footer?.contact_modal?.form?.submit_button}
                                label={props.t('submit_button')}
                            />

                            <h4 className="card-title mb-2 mt-4">
                                {props.t('Tooltip texts')}
                            </h4>
                            <TextField
                                label='Success'
                                className='flex-grow-1'
                                register={register}
                                name={`${lang}.footer.contact_modal.form.tooltip_success`}
                            />
                            <TextField
                                label='Error'
                                className='flex-grow-1'
                                register={register}
                                name={`${lang}.footer.contact_modal.form.tooltip_error`}
                            />
                        </CardBody>

                    </Card>
                </Col>
            </Row>


        </div >
    )
}


FormSettings.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(FormSettings);
