import React from 'react'
import { FormGroup } from 'reactstrap'
import { Controller } from "react-hook-form";
import { EditorProvider, Editor, Toolbar, BtnBold, BtnUndo, BtnRedo, Separator, DefaultEditor, BtnBulletList, BtnLink, BtnItalic } from 'react-simple-wysiwyg';


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const TextField = props => {

    const {
        register,
        className,
        error,
        name,
        required = true,
        label,
        invalidText = 'required field',
        type = 'text',
        disabled = false
    } = props

    return (
        <>
            <FormGroup className={className ? className : ''}>
                {
                    label ?
                        <label>
                            {label}
                        </label> : ''
                }
                <input
                    disabled={disabled}
                    type={type}
                    {...register(name, {
                        required: required
                    })}
                    className={`form-control`}
                    autoComplete="off"
                    placeholder={label}
                />
                {error && <label className='text-danger' >{invalidText}</label>}
            </FormGroup>
        </>
    )
}
export const TextareaField = props => {

    const {
        register,
        className,
        error,
        name,
        required = true,
        label,
        invalidText = 'required field',
        type = 'text',
        rows = 4
    } = props

    return (
        <>
            <FormGroup className={className ? className : ''}>
                <label>
                    {label}
                </label>
                <textarea
                    rows={rows}
                    type={type}
                    {...register(name, {
                        required: required
                    })}
                    className={`form-control`}
                    autoComplete="off"
                    placeholder={label}
                />
                {error && <label className='text-danger' >{invalidText}</label>}
            </FormGroup>
        </>
    )
}

export const TextEditor = props => {

    const {
        control,
        className,
        name,
        label,
        onReady,
        isExtend = true
    } = props

    return (
        <FormGroup className={`editor ${className ? className : ''}`}>
            {
                (label && typeof (label) === 'string') ?
                    <label>
                        {label}
                    </label>
                    :
                    (label && typeof (label) === 'object') ?
                        React.createElement(label.type, label.props, label.props.children)
                        :
                        ''
            }
            <Controller
                name={name}
                control={control}
                render={({ field: { value, onChange } }) => (


                    <CKEditor
                        editor={ClassicEditor}
                        data={value}
                        onReady={() => {
                            if (onReady) onReady()
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            onChange(data)
                        }}
                    />
                    // <EditorProvider>
                    //     <Editor value={value} onChange={onChange}>
                    //         <Toolbar>
                    //             <BtnUndo />
                    //             <BtnRedo />
                    //             <Separator />
                    //             <div className='color-btn'>
                    //                 <b>{'Set Color'}</b>
                    //                 <BtnItalic />
                    //             </div>

                    //             <BtnBold />
                    //             {
                    //                 isExtend ?
                    //                     <>
                    //                         <Separator />
                    //                         <BtnBulletList />
                    //                         <Separator />
                    //                         <BtnLink />
                    //                     </>
                    //                     :
                    //                     ''
                    //             }
                    //         </Toolbar>
                    //     </Editor>
                    // </EditorProvider>
                )}
            />
        </FormGroup>
    )
} 